<template>
  <v-dialog v-model="dialog" max-width="400px" min-width="400px">
    <v-card class="pb-3 pt-3">
      <v-card-text>
        <v-row>
          An animal with the same tag already exists. Would you like to update
          it?
        </v-row>
      </v-card-text>

      <v-card-actions class="pr-6">
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false"> Cancel </v-btn>
        <v-btn color="primary white--text" @click="approve"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "../main.js";

export default {
  props: [],
  components: {},
  name: "ZoomImages",
  data() {
    return {
      dialog: false,
      item: false,
      animal: null,
    };
  },
  methods: {
    approve() {
      eventBus.$emit("approve_animal_confirm", {
        item: this.item,
        animal: this.animal,
      });
      this.dialog = false;
    },
  },

  computed: {},
  created() {
    //if (!eventBus._events["appoval_window_open"]) {
      eventBus.$on("appoval_window_open", (item) => {
        this.item = item.item;
        this.animal = item.animal;
        this.dialog = true;
      });
   // }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        eventBus.$emit("end_loading_animal_ids");
      }
    },
  },
};
</script>
<style lang="css">
</style>